<template>
	<div id="app">
		<notifications group="user-message" position="right top" :speed="1" :duration="2500" class="user-message"/>
		<router-view></router-view>
	</div>
</template>

<script>
	// import semantic-ui dependencies
	import '../libs/semantic-ui/semantic.min.js';
	import '../libs/semantic-ui/semantic.min.css';

	export default {
		name: 'App',
	}
</script>

<style>
	/**
	* start of global css
	*
	*/
@import './assets/css/style.css';
	/*variables*/
	:root {
		--theme-color: #73bae6;
	}


	@font-face {
		font-family: 'Roboto-Regular';
		src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
		font-weight: normal;
		font-style: normal;
		font-display: swap;
	}

	*:not(i) {
		 font-family: 'Montserrat',
        sans-serif !important;
	}

	a:hover {
		cursor: pointer !important;
	}

	.nowrap {
		white-space: nowrap !important;
	}

	/** component transition when using router */
	.fade-leave-active, .fade-enter-active {
		transition: all .2s cubic-bezier(0.1, 0.1, 0.1, 0.1);
	}

	.fade-enter, .fade-leave-to {
		transform: translateX(10px);
		opacity: .1;
	}
	.jump-leave-active, .jump-enter-active {
		transition: all .2s cubic-bezier(0.1, 0.1, 0.1, 0.1);
	}
	.jump-enter, .jump-leave-to {
		opacity: .8;
	}


	/*disable text selection*/
	/** {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}*/

	/**
	*
	* end of global css
	*/

</style>
