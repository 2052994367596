const fields = {
};

const services = {
    login: '/login',
    checkSession: '/getSessionUser',
    findAll: '/private/findAll',
    insert: '/private/insert',
    update: '/private/update',
    delete: '/private/delete',
    insertIfNotExist: '/private/insert_if_not_exist',
    updateIfNotExist: '/private/update_if_not_exist',
    findOne: '/private/findOne',
    aggregate: '/private/aggregate',
    find: '/private/find',
    share:'/private/share/create',
    generatePDF:'/private/generateTemplatePDF',
    task:{
        seen:'/private/task/seen',
    },
    user: {
        list: '/private/user/list',
        create: '/private/user/create',
        update: '/private/user/update',
        delete: '/private/user/remove',
        uploadPhoto: '/private/user/upload_photo',
    },
    document: {
        create: '/private/document/create',
        task: {update: '/private/task/update'},
        upload: '/private/document/upload',
        updateDispatcher: '/private/document/update_dispatcher',
        updateStatus: '/private/share/update_status',
        duplicateDocument: '/private/document/duplicate',
        activateDocument: '/private/document/activate'
    },
    portfolio: {
        add: '/private/portfolio/add',
        remove: '/private/portfolio/remove'
    },
    attachment: {
        activate_images: '/private/attachment/update',
        update_default: '/private/attachment/update_default',
        remove: '/private/attachment/remove'
    },
	exportExcel: '/private/exportExcel',
	export: '/private/export'
};

const collections = {
    user: "user",
    documents: "documents",
    role: 'role',
    group: 'group',
    notification: 'notification',
    actions: 'actions',
    contact: 'contact',
    action: 'action',
    courtier: 'courtier',
    permissions: 'permissions',
    vehicule: 'vehicule',
    assurance: 'assurance',
    attachment: 'attachment',
    designation: 'designations',
    expert_adverse: 'expert_adverse',
    honoraire: 'honoraire'
};
const utils= {
    isEmpty: function (obj){
        return _.isEmpty(obj)
    }
}
const messages = {
    // server error codes
    '-1': "TECHNICAL_ERROR", // event bus replay delay
    0: "TECHNICAL_ERROR",
    1: "USER_OR_PASSWORD_INCORRECT",
    2: "DUPLICATED_USERNAME",
    3: "WRONG_PASSWORD",
    4: "SESSION_EXPIRED",
    5: "DOCUMENT_NOT_FOUND",
    6: "DOCUMENT HAS ALREADY AN OPEN TASK",
    7: "exist déjà",
    8: "tache déja valider",
    111: "Version invalide! veuillez mettre à jour votre version",
    _200:"fichier  charger avec success",

    technical_error: "TECHNICAL_ERROR",
    permission: {
        update_succeeded: "permission updated successfully",
        add_succeeded: "permission added successfully"
    },
    user: {
        create_succeeded: "utilisateur a été créé avec succès!",
        update_succeeded: "utilisateur mis à jour avec succès!",
        delete_succeeded: "utilisateur supprimé avec succès!",
    },
    role: {
        add_succeeded: 'role a été créé avec succès!',
        update_succeeded: 'role mis à jour avec succès!',
        delete_succeeded: 'role deleted successfully'
    },
    assurance: {
        create_succeeded: "Assurance a été créé avec succès!",
        update_succeeded: "Assurance mis à jour avec succès!",
        delete_succeeded: "Assurance supprimé avec succès!",
    },
    marque: {
        create_succeeded: "Marque a été créé avec succès!",
        update_succeeded: "Marque mis à jour avec succès!",
        delete_succeeded: "Marque supprimé avec succès!",
    },
    model: {
        create_succeeded: "Modéle a été créé avec succès!",
        update_succeeded: "Modéle mis à jour avec succès!",
        delete_succeeded: "Modéle supprimé avec succès!",
    },
    courtier: {
        create_succeeded: "Courtier a été créé avec succès!",
        update_succeeded: "Courtier mis à jour avec succès!",
        delete_succeeded: "Courtier supprimé avec succès!",
    },
    action: {
        update_succeeded: "Action mis à jour avec succès!"
    },
    document: {
        creation_succeeded: 'dossier a été créé avec succès!',
        update_succeeded: 'dossier mis a jours avec succés!',
        updateDispatcher_succeeded: 'dispatcher mis a jours avec succés!'
    },
    designation: {
        create_succeeded: "Désignation a été créé avec succès!",
        update_succeeded: "Désignation mis à jour avec succès!",
        delete_succeeded: "Désignation supprimé avec succès!",
    },
    expert_adverse: {
        create_succeeded: "Expert adverse a été créé avec succès!",
        update_succeeded: "Expert adverse mis à jour avec succès!",
        delete_succeeded: "Expert adverse supprimé avec succès!",
    },
    honoraire: {
        create_succeeded: "créé avec succès!",
        update_succeeded: "mis à jour avec succès!",
        delete_succeeded: "supprimé avec succès!",
    },
};

export default {
	fields,
    services,
    messages,
    collections,
    utils
};