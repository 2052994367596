<template>
    <img class="avatar" :src="image_src" alt/>
</template>

<script>
    const avatar_def = () => require('@/assets/images/avatar.jpg')
    export default {
        props:{user:Object},
        name: "avatar",
        computed:{
            server_address(){
                return this.$store.state.SERVER_ADDRESS;
            },
            image_src(){
                return (this.user.user_image && this.user.user_image !== '') ? this.server_address +'/'+ this.user.user_image :  require('@/assets/images/avatar.jpg')
            }
        },
        methods:{
            get_image_src(){
                return require('@/assets/images/avatar.jpg')
            }
        }
    }
</script>

<style scoped>

</style>