import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import shared from './shared'
import vSelect from 'vue-select'

const moment = require('moment-timezone')
require('moment/locale/fr')
moment.tz.setDefault('Africa/Casablanca')

Vue.config.productionTip = false;
Vue.prototype.$SHARED = shared;

// notifications
import Notifications from 'vue-notification'
Vue.use(Notifications);

// modal
import VModal from 'vue-js-modal'
Vue.use(VModal, {dialog: true});

// image viewer
import Viewer from 'v-viewer'
Vue.use(Viewer);

//vue moment
Vue.use(require('vue-moment'),{
	moment
});

Vue.component('v-select', vSelect);

//vue unit
import VueUnits from 'vue-units';
Vue.use(VueUnits);

// counter
import VueCountdown from '@chenfengyuan/vue-countdown';
Vue.component(VueCountdown.name, VueCountdown);

// avatar component
import avatar from '@/components/avatar';
Vue.component('avatar', avatar);

// date-time picker
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);


// vuelidate
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect);

Vue.directive("uppercase", {
	update: function (el) {
		el.value = el.value.toUpperCase()
		el.dispatchEvent(new Event('input'))
	}
})
// Global event bus
// Vue.prototype.$eb = new Vue();

// session middleware
// check if authenticated if not redirect to /login
router.beforeEach((to, from, next) => {
	if(_.isEmpty(store.state.current_user) && to.name !== 'login'){
		store.dispatchAsync("/getSessionUser", {}, true, true).then(
			data => {
				// set store user
				store.commit("SET_CURRENT_USER", data);
				store.dispatch("get_actions")
				next()
			},
			err => {
				if(to.name !== 'login') {
					next({name:'login'})
				}
			}
		);
	}
	else{
		next()
	}
});

// disable vue logs in production
if (process.env.NODE_ENV === 'production') {
	console.log = function() {}
}

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
