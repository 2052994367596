import Vue from 'vue'
import VueRouter from 'vue-router'

// views
const viewLogin = () => import('@/views/login');
const viewPrivate = () => import( '@/views/private');
const viewHome = () => import('@/views/home');
const viewFolder= () => import('@/views/folder');
const viewDashboard= () => import('@/views/dashboard');
const administration = () => import('@/views/administration');
const viewSearch = () => import('@/views/search');
const imageViewer = () => import('@/views/image-viewer');
const statistic = () => import('@/views/statistic');
const paiement = () => import('@/views/paiement');

// components
const viewUsers = () => import('@/components/user/userCrud');
const userCrud = () => import('@/components/user/userCrud');
const userList = () => import('@/components/user/userList');
const marqueCrud = () => import('@/components/vehicle/marqueCrud');
const marqueList = () => import('@/components/vehicle/marqueList');
const modelCrud = () => import('@/components/vehicle/modelCrud');
const modelList = () => import('@/components/vehicle/modelList');
const assuranceCrud = () => import('@/components/assuranceCrud');
const assuranceList = () => import('@/components/assuranceList');
const taskList = () => import('@/components/taskList');
const taskCrud = () => import('@/components/taskCrud');
const actionList = () => import('@/components/actionList');
const actionCrud = () => import('@/components/actionCrud');
const courtierList = () => import('@/components/courtierList');
const courtierCrud = () => import('@/components/courtierCrud');
const folderList = () => import('@/components/folderList');
const folderEdit_dispatcher = () => import('@/components/folderEdit-dispatcher');
const desigFournitureList = () => import('@/components/desigFournitureList');
const desigFournitureCrud = () => import('@/components/desigFournitureCrud');
const desigOeuvreList = () => import('@/components/desigOeuvreList');
const desigOeuvreCrud = () => import('@/components/desigOeuvreCrud');
const expertAdverseList = () => import('@/components/expertAdverseList');
const expertAdverseCrud = () => import('@/components/expertAdverseCrud');
const honoraireList = () => import('@/components/honoraireList');
const honoraireCrud = () => import('@/components/honoraireCrud');

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'login',
		component: viewLogin,
	},
	{
		path: '/private',
		name: 'private',
		component: viewPrivate,
		children: [
			{
				path: 'image-viewer/:image_type/:image_src/:image_id_doc',
				name: 'imageViewer',
				component: imageViewer,
				props: true
			},
			{
				path: 'home',
				name: 'home',
				component: viewHome,
				props:true,
				meta: {title: 'Home'},
			},
			{
				path: 'home/:action_id',
				name: 'home_action',
				component: viewHome,
				props: true
			},
			{
				path: 'statistic',
				name: 'statistic',
				component: statistic,
				meta: {
					title: 'Statistiques'
				},
			},
			{
				path: 'folder',
				name: 'folder',
				component: viewFolder,
				props: true,
				children:[
					{
						path: 'create',
						name: 'folderCreate',
						component: viewFolder,
						meta: {title: 'Dossier', sub_title:'Creation dossier'},
					},
					{
						path: 'edit',
						name: 'folderEdit',
						component: viewFolder,
						props: true,
						meta: {title: 'Dossier', sub_title:'Edition dossier'},
					}
				]
			},
			{
				path: 'dashboard',
				name: 'dashboard',
				component: viewDashboard,
				meta: {title: 'Dashboard', sub_title: ''},
			},
			{
				path: 'search',
				name: 'search',
				props: true,
				component: viewSearch,
				meta: {title: 'Recherche', sub_title: ''},
			},
			{
				path: 'users',
				name: 'users',
				component: viewUsers,
			},
			{
				path: 'administration',
				name: 'administration',
				component: administration,
				children:[
					{
						path: 'user/list',
						name: 'userList',
						component: userList,
						meta: {title: 'Administration', sub_title: 'Utilisateur'},
					},
					{
						path: 'user/crud',
						name: 'userCrud',
						component: userCrud,
						props: true,
						meta: {title: 'Administration', sub_title: 'Utilisateur'},
					},
					{
						path: 'task/list',
						name: 'taskList',
						component: taskList,
						meta: {title: 'Administration', sub_title: 'Tâche'},
					},
					{
						path: 'task/crud',
						name: 'taskCurd',
						component: taskCrud,
						meta: {title: 'Administration', sub_title: 'Tâche'},
					},
                    {
                        path: 'marque/list',
                        name: 'marqueList',
                        component: marqueList,
						meta: {title: 'Administration', sub_title: 'Marque'},
                    },
                    {
                        path: 'marque/crud',
                        name: 'marqueCrud',
                        component: marqueCrud,
                        props: true,
						meta: {title: 'Administration', sub_title: 'Marque'},
                    },
                    {
                        path: 'modele/list',
                        name: 'modelList',
                        component: modelList,
						meta: {title: 'Administration', sub_title: 'Modéle'},
                    },
                    {
                        path: 'modele/crud',
                        name: 'modelCrud',
                        component: modelCrud,
                        props: true,
						meta: {title: 'Administration', sub_title: 'Modéle'},
                    },
                    {
                        path: 'assurance/list',
                        name: 'assuranceList',
                        component: assuranceList,
						meta: {title: 'Administration', sub_title: 'Assurance'},
                    },
                    {
                        path: 'assurance/crud',
                        name: 'assuranceCrud',
                        component: assuranceCrud,
                        props: true,
						meta: {title: 'Administration', sub_title: 'Assurance'},
                    },
					{
						path: 'action/list',
						name: 'actionList',
						component: actionList,
						meta: {title: 'Administration', sub_title: 'Actions'},
					},
					{
						path: 'action/crud',
						name: 'actionCrud',
						component: actionCrud,
						props: true,
						meta: {title: 'Administration', sub_title: 'Actions'},
					},
					{
						path: 'courtier/list',
						name: 'courtierList',
						component: courtierList,
						meta: {title: 'Administration', sub_title: 'Courtier'},
					},
					{
						path: 'courtier/crud',
						name: 'courtierCrud',
						component: courtierCrud,
						props: true,
						meta: {title: 'Administration', sub_title: 'Courtier'},
					},
					{
						path: 'folder/list',
						name: 'folderList',
						component: folderList,
						meta: {title: 'Administration', sub_title: 'Dossier'},
					},
					{
						path: 'folder/edit',
						name: 'folderEdit_dispatcher',
						component: folderEdit_dispatcher,
						props: true,
						meta: {title: 'Administration', sub_title: 'Dossier'},
					},
					{
						path: 'designation-fourniture/list',
						name: 'desigFournitureList',
						component: desigFournitureList,
						meta: {title: 'Administration', sub_title: 'Désignation Fourniture'},
					},
					{
						path: 'designation-main-oeuvre/list',
						name: 'desigOeuvreList',
						component: desigOeuvreList,
						meta: {title: 'Administration', sub_title: 'Désignation main d\'oeuvre'},
					},
					{
						path: 'designation-fourniture/crud',
						name: 'desigFournitureCrud',
						component: desigFournitureCrud,
						props: true,
						meta: {title: 'Administration', sub_title: 'Désignation Fourniture'},
					},
					{
						path: 'designation/crud',
						name: 'desigOeuvreCrud',
						component: desigOeuvreCrud,
						props: true,
						meta: {title: 'Administration', sub_title: 'Désignation main d\'oeuvre'},
					},
					{
						path: 'expert-adverse/list',
						name: 'expertAdverseList',
						component: expertAdverseList,
						meta: {title: 'Administration', sub_title: 'Expert Adverse'},
					},
					{
						path: 'expert-adverse/crud',
						name: 'expertAdverseCrud',
						component: expertAdverseCrud,
						props: true,
						meta: {title: 'Administration', sub_title: 'Expert Adverse'},
					},
					{
						path: 'honoraire/list',
						name: 'honoraireList',
						component: honoraireList,
						meta: {title: 'Administration', sub_title: 'Honoraire'},
					},
					{
						path: 'honoraire/crud',
						name: 'honoraireCrud',
						component: honoraireCrud,
						props: true,
						meta: {title: 'Administration', sub_title: 'Honoraire'},
					},
				]
			},
			{
				path: 'paiement',
				name: 'paiement',
				component: paiement,
				meta: {title: 'Paiement'},
			}
		]
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router
