import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import router from '../router/index.js';
import $SHARED from '../shared'

Vue.use(Vuex);
let _vue = new Vue();
const isProduction = process.env.NODE_ENV === 'production';

if (!isProduction) {
	log('DEV');
	// allow ajax cross
	$.ajaxSetup({
		crossDomain: true,
		xhrFields: {
			withCredentials: true
		}
	});
}

// TODO -changes to config.json no longer apply after build- find an other way to load config json data into project
const config = require('@/../../config.json');

const server_addr_env_dev = window.location.protocol + '//' + window.location.hostname + ":" + config.serverPort;
const SERVER_ADDRESS = isProduction ? window.location.origin : server_addr_env_dev;

const state = {
	SERVER_ADDRESS,
	loading: false,
	current_user: {},
	action_list:[],
	notification_list: [],
	notification_count: 0,
	config: config,
	search_fields:{
		search_query: {},
		search_fields: {
			montant_devis: {min: null, max: null},
			montant_facture: {min: null, max: null}
		},
		date_creation: null,
		date_sinistre: null,
		date_cloture: {
			start: moment()
				.subtract(1, 'days')
				.set({"hour": 0, "minute": 0, "second": 0})
				.valueOf()
		}
	},
	notification_interval: null,
	cadre_expertise: ['ARB', 'BDG', 'EC', 'APPR', 'AEJ', 'TRPI', 'RC', 'DTA', 'DC', 'INC', 'VOL', 'DR']
};

const getters = {
	findAction: (state) => (query) => {
		return _.filter(state.action_list, query);
	},
	findOneAction: (state) => (query) => {
		return _.find(state.action_list, query);
	}
};

const mutations = {
	SET_LOADING(state, payload) {
		state.loading = payload
	},
	SET_CURRENT_USER(state, payload) {
		state.current_user = payload
	},
	SET_ACTIONS(state, payload){
		state.action_list = payload
	},
	SET_NOTIFICATIONS(state, payload){
		state.notification_list = payload
	},
	SET_NOTIFICATION_COUNT(state, payload){
		state.notification_count = payload
	}
};

const actions = {
	get_actions(context, payload) {
		console.log('get_actions');
		const data = {
			collection: $SHARED.collections.action,
			query: {},
			options: {page: 1, limit: -1, sort: {name: 1}}
		};
		requestFind(data).then(ar => {
			context.commit('SET_ACTIONS', ar.docs);
		}, code => {
			this.$notify({
				group: 'user-message',
				type: 'error', // warn , error, success, info
				text: $SHARED.messages.technical_error
			});
		})
	},
	get_notifications(context){
		console.log('get_notifications');
		const now = new Date().getTime();
		let query = {
			sender: {$ne: context.state.current_user.username},
			"receiver._id": context.state.current_user._id,
			date_creation: {$gt: (now - 10000)}
		};

		const data = {
			collection: $SHARED.collections.notification,
			query: query,
			options: {page: 1, limit: 10, sort: {date_creation: -1}}
		};

		requestFind(data, false).then(ar => {
			context.commit('SET_NOTIFICATION_COUNT', ar.count);
			context.commit('SET_NOTIFICATIONS', ar.docs);
		}, code => {
			this.$notify({
				group: 'user-message',
				type: 'error', // warn , error, success, info
				text: $SHARED.messages.technical_error
			});
		})
	}
}
let store = new Vuex.Store({
	state,
	getters,
	mutations,
	actions
});

export default store;

/**
 * @param url <String> request url
 * @param data <JSON> request data
 * @param rejectFailure <Boolean> on request failure or not succeeded :
 *     if its equal false: display a notification with a store message that have the  same code as the replay's message.
 *     if its equal true:  just reject the property message of the replay.
 * @param use_loader <Boolean> show the application loader while request is in progress or not
 * @param debug <Boolean> debug request data
 * @description send http request
 *
 */
function dispatchAsync(url, data, rejectFailure = false, use_loader = true, debug = true) {

	log('`store.dispatchAsync`\n\turl: %s, \n\tdata: %o', url, debug ? data : '???');

	data = JSON.stringify(data);
	url = SERVER_ADDRESS + url;

	return new Promise((resolve, reject) => {
		// show loader
		use_loader && store.commit('SET_LOADING', true);

		let headers = {"Content-Encoding": "gzip", "Content-Type": "application/json; charset=utf-8"};
		/*if (!isProduction)
			headers = {"Content-Type": "text/plain; charset=utf-8"};*/

		$.ajax({
			url,
			headers,
			data,
			type: "POST",
			datatype: "json",
			complete: () => {
				// hide loader
				use_loader && store.commit('SET_LOADING', false);
			}
		}).done(resp => {
			log('`AjaxResp`\n\tresp: %o', resp);
			if (resp.succeeded) {
				resolve(resp.data);
			} else {
				// if session is expiered
				if (resp.message === 4) {
					_vue.$notify({
						group: 'user-message',
						type: 'error', // warn , error, success, info
						text: _vue.$SHARED.messages[4]
					});
					router.push({name: "login"});
				}
				reject(resp.message);

			}
		}).fail(err => {
			console.error('`AjaxFail`\n\terr: %o', err);
			if (rejectFailure) {
				reject(0);
			} else {
				reject(0);
			}
		});
	})
};

/**
 * @param {Object} args
 * @param {String} args.collection collection from where we want to get the document(s).
 * @param {Object} args.query query to select the document(s).
 * @param {Object} [args.options] find options (skip, limit, sort, fields...).
 * @description find docs from a specific collection
 * @return {Promise} return replay.data on resolve, replay.code on reject
 */
function requestFind({collection, query, options, concat_pipeline}, use_loader= true) {
	const data = {collection, query, concat_pipeline};
	if (options !== undefined) {
		data.options = options;
	}
	return dispatchAsync(_vue.$SHARED.services.find, data,false,use_loader);
}

/**
 * @param {Object} args
 * @param {String} args.collection collection name.
 * @param {Object} args.query the insert that we want to do.
 * @description insert doc in specific collection
 * @return {Promise} return replay.data on resolve, replay.code on reject
 */
function requestInsert({collection, query}) {
	const data = {collection, query};
	return dispatchAsync(_vue.$SHARED.services.insert, data);
}

/**
 * @param {Object} args
 * @param {String} args.collection collection name.
 * @param {Object} args.query query to select the document(s).
 * @param {Object} args.update the changes that we want to do.
 * @param {Object} args.options update options(multi, upsert).
 * @description update|add doc of|in specific collection
 * @return {Promise} return replay.data on resolve, replay.code on reject
 */
function requestUpdate({collection, query, update, options = {upsert: false}, remove_id= false }) {
	if(remove_id){
		update = JSON.parse(JSON.stringify(update))
		delete update.$set._id;
	}
	return dispatchAsync(_vue.$SHARED.services.update, {collection, query, update, options});
}

/**
 * @param {Object} args
 * @param {String} args.collection collection where we want to delete the document.
 * @param {Object} args.query query to select the document.
 * @description delete doc of a specific collection
 * @return {Promise} return replay.data on resolve, replay.code on reject
 */
function requestDelete({collection, query}) {
	return dispatchAsync(_vue.$SHARED.services.delete, {collection, query});
}

/**
 * @param {Object} args
 * @param {String} args.collection collection from where we want to get the document.
 * @param {Object} args.pipeline pipeline to run in the aggregation.
 * @description run aggregation on a specific collection
 * @return {Promise} return replay.data on resolve, replay.code on reject
 */
function requestAggregate({collection, pipeline, batchSize = null}) {
	let data = {collection, pipeline}

	if(batchSize)
		data.batchsize = batchSize

	return dispatchAsync(_vue.$SHARED.services.aggregate, data);
}

// current date or parse to milliseconds
store.getMilliseconds = function (date = null) {
	return date === null ? new Date().getTime() : moment(date).utc().valueOf();

	// var milliseconds = Date.now()
	// var milliseconds = new Date().getTime()
	// var milliseconds = $.now()
	// var milliseconds = moment().utc().valueOf()

	// var timestamp = moment().utc().unix()
	// var timestamp_string = moment().utc().format("X")
};
// milliseconds to date as ISO8601
store.getDateAsIso = function (milliseconds) {
	if (milliseconds === '' || milliseconds === undefined)
		return '';
	return moment(milliseconds).utc().toISOString();
};
// milliseconds to date
store.getDate = function (milliseconds, format = 'DD/MM/YYYY') {
	if (milliseconds === '' || milliseconds === undefined || milliseconds === null)
		return '';
	return moment(milliseconds).format(format);
};
// passed time from a date in milliseconds
store.getTimeFromNow = milliseconds => moment(milliseconds).utc().fromNow();

store.dateToQuery = function(start, end){
	let result = {}
	if(start){
		result.$gt = start
	}
	if(end){
		result.$lt = end + 86240000
	}
	/*if(start === end){
		result.$lt = end + 86240000
	}*/
	console.log("REESULT", result)
	return result
}
store.user_message = {
	// server error codes
	'-1': "TECHNICAL_ERROR", // event bus replay delay
	0: "TECHNICAL_ERROR",
};

store.dispatchAsync = dispatchAsync;
store.requestUpdate = requestUpdate;
store.requestDelete = requestDelete;
store.requestInsert = requestInsert;
store.requestFind = requestFind;
store.requestAggregate = requestAggregate;